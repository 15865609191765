<template>
  <en-table-layout :tableData="tableData.data">
    <template slot="toolbar">
      <el-form-item label="创建时间">
        <el-date-picker
          style="width:220px;"
          v-model="time"
          size="medium"
          type="daterange"
          @change="daterangeDateChange"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          :default-time="['00:00:00','23:59:59']"
          align="center"
          :editable="false"
          unlink-panels
          range-separator="-"
          clearable
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="金额范围">
        <el-select v-model="disabled" clearable size="medium">
          <el-option label="不限" value="-1"></el-option>
          <el-option label="0~200" value="0"></el-option>
          <el-option label="200~500" value="1"></el-option>
          <el-option label="500~1000" value="2"></el-option>
          <el-option label="1000~2000" value="3"></el-option>
          <el-option label="2000以上" value="4"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="关键词">
        <el-input
          style="width:250px;"
          size="medium"
          v-model="inform.keyword"
          placeholder="请输入方案名称/人员名称"
          clearable
        ></el-input>
      </el-form-item>

      <el-button @click="seachMember" size="small" type="primary">搜索</el-button>
    </template>

    <template slot="table-columns">
      <el-table-column label="方案套餐名称">
        <template slot-scope="scope">{{ scope.row.title }}</template>
      </el-table-column>
      <el-table-column prop="price" label="套餐总金额">
        <template slot-scope="scope">{{ scope.row.price | unitPrice('￥') }}</template>
      </el-table-column>
      <el-table-column prop="goods_count" label="套餐内商品数">
        <template slot-scope="scope">{{ scope.row.goods_count }}</template>
      </el-table-column>
      <el-table-column prop="nickname" label="创建人员">
        <template slot-scope="scope">销售部-{{ scope.row.nickname }}</template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">{{ scope.row.create_time | unixToDate }}</template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" plain @click="handleClickDetail(scope.row)" type="primary">查看</el-button>
          <el-button
            size="mini"
            type="danger"
            plain
            @click="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </template>

    <el-pagination
      slot="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="inform.page_no"
      :page-size="inform.page_size"
      :page-sizes="MixinPageSizes"
      :layout="MixinTableLayout"
      background
      :total="tableData.total"
    ></el-pagination>
  </en-table-layout>
</template>

<script>
import {handleDownload} from '@/utils';
import {RegExp} from '@/../ui-utils';
import * as API_Shop from '@/api/shop';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'programmeRecord',
  components: {EnTableLayout},
  data() {
    return {
      time: '',
      disabled: '-1',
      rules: {
        nickname: [
          {
            required: true,
            message: '请输入用户名称',
            trigger: 'blur'
          }
        ],
        member_id: [
          {
            required: true,
            message: '请输入用户ID',
            trigger: 'blur'
          }
        ],
        mobile: [
          {
            required: true,
            message: '请输入电话号码',
            trigger: 'blur'
          },
          {
            min: 11,
            max: 11,
            message: '长度在11个字符',
            trigger: 'blur'
          }
        ],
        disabled: [{
          required: true,
          message: '请选择区域',
          trigger: 'change'
        }]
      },
      total: 0,
      inform: {
        keyword: '',
        page_size: 20,
        page_no: 1,
        type: 2
      },
      params: [],
      page: {},
      tableData: {}
    };
  },
  created() {
    this.getList();
  },
  methods: {
    daterangeDateChange(val) {
      this.inform.start_time = val ? parseInt(val[0] / 1000) : '';
      this.inform.end_time = val ? parseInt(val[1] / 1000) : '';
    },
    seachMember() {
      const {disabled} = this;
      if (disabled === '-1') {
        this.inform.price_start = '';
        this.inform.price_end = '';
      } else if (disabled === '0') {
        this.inform.price_start = '0';
        this.inform.price_end = '199';
      } else if (disabled === '1') {
        this.inform.price_start = '200';
        this.inform.price_end = '499';
      } else if (disabled === '2') {
        this.inform.price_start = '500';
        this.inform.price_end = '999';
      } else if (disabled === '3') {
        this.inform.price_start = '1000';
        this.inform.price_end = '1999';
      } else if (disabled === '4') {
        this.inform.price_start = '2000';
        this.inform.price_end = '';
      }
      this.getList();
    },
    getList() {
      let params = this.inform;
      API_Shop.getGoodsMktpage(params).then(res => {
        this.tableData = res;
      });
    },
    // 查看详情
    handleClickDetail(row) {
      this.$router.push({path: `/tools/sale-manage/programmeDetail/${row.id}`});
    },
    handleDelete(index, row) {
      this.$confirm('确定要删除该方案记录吗?', '提示', {
        type: 'warning'
      })
        .then(() => {
          API_Shop.deleteGoodsMktpage(row.id).then(res => {
            this.getList();
            this.$message.success('删除成功');
          });
        })
        .catch(_ => {
        });
    },
    handleSizeChange(val) {
      this.inform.page_size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.inform.page_no = val;
      this.getList();
    }
  }
};
</script>

<style scoped lang="scss">
</style>
